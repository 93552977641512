<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-4 is-one">
                <h4 class="title-in-h">{{$t('property.index_one.header')}}</h4>
            </div>
            <div class="col-md-8 d-none">
                <l-alert type="danger" dismissible="" icon="fa fa-exclamation-circle">
                    Tienes hasta el día 6 de "mes en curso" para conciliar tus reservaciones
                </l-alert>
            </div>
        </div>



    <div class="row">

        <div class="col-12">
            <card>
                <div>
                    <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
                        <div class="mb-3" style="width: 200px">

                            <l-button class="btn-success" @click="createItem()" v-if="info_page.btn">{{$t('property.index.new')}}</l-button>
                        </div>
                    </div>
                    <div class="col-sm-12">
                        <div class="table-box">
                            <div class="table-header">
                                <div class="item total">
                                    <label>{{$t('property.table.reservation_today')}}</label>
                                </div>
                                <div class="item">
                                    <span>{{$t('property.table.pendient')}}</span>
                                </div>
                                <div class="item">
                                    <span>{{$t('property.table.arrive')}}</span>
                                </div>
                                <div class="item">
                                    <span>{{$t('property.table.cancelled')}}</span>
                                </div>
                            </div>
                            <div class="table-body">
                                <div class="item">
                                    <span>2</span>
                                </div>
                                <div class="item">
                                    <span>2</span>
                                </div>
                                <div class="item">
                                    <span>2</span>
                                </div>
                            </div>
                        </div>
                        <!--<div class="table-box mt-4">
                            <div class="table-header">
                                <div class="item total">
                                    <label>{{$t('property.table.comision_today')}}</label>
                                </div>
                                <div class="item">
                                    <span>{{$t('property.table.pendient')}}</span>
                                </div>

                                <div class="item">
                                    <span> </span>
                                </div>
                                <div class="item">
                                    <span>{{$t('property.table.arrive')}}</span>
                                </div>
                            </div>
                            <div class="table-body">
                                <div class="item">
                                    <span>2</span>
                                </div>
                                <div class="item">
                                    <span> </span>
                                </div>
                                <div class="item">
                                    <span>2</span>
                                </div>
                            </div>
                        </div>-->
                    </div>
                </div>
            </card>
        </div>
    </div>    </div>

</template>
<script>
    import {Table, TableColumn, Select, Option} from 'element-ui'
    import {Pagination as LPagination, Breadcrumb, BreadcrumbItem} from 'src/components/index'
    import LAlert from 'src/components/Alert';
    //import users from 'src/pages/Dashboard/Tables/users'
    import swal from 'sweetalert2'
    import Fuse from 'fuse.js'
    import PropertyService from '../../../js/services/PropertyService';


    const propertyService = new PropertyService();

    export default {
        components: {
            LPagination,
            LAlert,
            Breadcrumb,
            BreadcrumbItem,
            [Select.name]: Select,
            [Option.name]: Option,
            [Table.name]: Table,
            [TableColumn.name]: TableColumn
        },
        computed: {
            pagedData() {
                return this.tableData.slice(this.from, this.to)
            },
            /***
             * Searches through table data and returns a paginated array.
             * Note that this should not be used for table with a lot of data as it might be slow!
             * Do the search and the pagination on the server and display the data retrieved from server instead.
             * @returns {computed.pagedData}
             */
            queriedData() {
                let result = this.tableData
                if (this.searchQuery !== '') {
                    result = this.fuseSearch.search(this.searchQuery)
                    this.pagination.total = result.length
                }
                return result.slice(this.from, this.to)
            },
            to() {
                let highBound = this.from + this.pagination.perPage
                if (this.total < highBound) {
                    highBound = this.total
                }
                return highBound
            },
            from() {
                return this.pagination.perPage * (this.pagination.currentPage - 1)
            },
            total() {
                this.pagination.total = this.tableData.length
                return this.tableData.length
            }
        },
        data() {
            return {
                info_page:{
                    btn: true
                },
                type: ['', 'info', 'success', 'warning', 'danger'],
                breadcrumbs:[
                    {
                        name: this.$t('property.index.home'),
                        path: '/admin/properties',
                        type: '',
                    },
                    {
                        name: this.$t('property.index.title'),
                        path: '',
                        type: 'active',
                    }
                ],
                items:[],
                pagination: {
                    perPage: 10,
                    currentPage: 1,
                    perPageOptions: [5, 10, 25, 50],
                    total: 0
                },
                searchQuery: '',
                propsToSearch: ['name'],
                tableData: [],
                fuseSearch: null,
                section: 'PROPERTY'
            }
        },
        created(){
            this.chargerItems();
            this.$store.dispatch('CHANGE_SECTION',this.section);
        },
        methods: {
            chargerItems(){
                if(this.$route.name==='PropertyShow'){
                   //console.log('PropertyShow')
                    this.info_page.btn = false;
                }
              // TODO: FALTA QUE HAGA ALGO ESTA VISTA

            },


        },
    }
</script>
<style lang="scss">
    .is-one{
        padding-bottom: 25px;
    }
    .table-box{
        width: 100%;
        display: inline-block;
        border: 2px solid #dee2e6;
        text-align: center;
        .table-header{
            height: 60px;
        }
        .table-body{
            height: 60px;
            background-color: #dee2e6;
            .item{
                line-height: 60px;
                height: 60px;
            }
        }
        .item{
            width: calc(100% / 3);
            display: inline-block;
            height: 30px;
            label, span{
                font-size: 14px;
                color: #000000;
                display: inline-block;
            }
            label{
                line-height: 30px;
                font-weight: 600;
            }

            &.total{
                width: 100%;
            }
        }
    }
</style>
